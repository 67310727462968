*{
	margin:0;
	padding:0;
	font-family: 'Raleway', sans-serif;
}
#page-wrapper{
	background: white;
	/*font*/
	color: #333;
}
/*ALL*/
.noSelect{/*deleting highlighting from items*/
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}